* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  background-color: #01010e;
}

body {
  margin: 0;
}

body, h1, h2, h3, h4, h5, h6, .text{
  font-family: 'Arimo', 'Open Sans', 'Hevlvetica Neue', Helvetica, sans-serif;
}

.uk-animation-slow {
  animation: 2.5s ease-out both;
}

.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.padding-right-s {
  padding-right: 0.5rem;
}

.padding-left-s {
  padding-left: 0.5rem;
}

.padding-right {
  padding-right: 1rem;
}

.padding-left {
  padding-left: 1rem;
}

.padding-right-m {
  padding-right: 1.5rem;
}

.padding-left-m {
  padding-left: 1.5rem;
}

.padding.outer {
  padding: 3rem 5rem;
}

@media screen and (max-width: 1000px) {
  .padding.outer {
      padding: 3rem 2rem;
  }
}

@media screen and (max-width: 800px) {
  .padding.outer {
      padding: 3rem 1rem;
  }
}


.red {
  color: #ea3838;
}
.redish {
  color: #ea3838;
}

.light {
  color: #FBF5F3;
}

.bg-ua-yellow {
  background: #FFD700;
}

.bg-ua-blue {
  background: #0057B7;
}

.text-center {
  text-align: center;
}


.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-l {
  font-size: 3rem;
}

.text-xl {
  font-size: 4rem;
}

@media screen and (max-width: 800px) {
  .text-l {
    font-size: 2rem;
  }
  .text-xl {
    font-size: 3rem;
  }
}

.main-grid-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.main-grid {
  display: flex;
}

.details {
  display: flex;
  flex-direction: column;
}

.day-wrapper, 
.description {
  flex: 1;
}

.day-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.day-wrapper h1 {
  font-size: 3rem;
  margin-bottom: 0;
  margin-top: 1.8rem;
}

.grid-item-template{
  display: none;
}

.grid-title,
.grid-date {
  text-align: center;
}

.flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.self-end {
  justify-self: flex-end;
}

.block {
  display: block !important;
}

.grid{
  display: grid;
  grid-template: "mainData mainData . ."
                 "mainData mainData . ."
                 ". . hlcpt ."
                 ". . hlcpt ."
                 "pli pli li msl";
  text-align: center;
  align-content: center;
  
  grid-gap: 1.5rem;
}

.grid-general {
  grid-template:"title-ua title-ua title-ua title-ua title-ua"
                "children war-events war-events war-events war-events"
                "civilian civilian civilian healthcare healthcare"
                "refugees refugees institution institution institution"
                ". . . . ."
                "title-ru title-ru title-ru title-ru title-ru"
                "enemy enemy enemy enemy enemy";
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .grid.grid-general {
    grid-template:"title-ua title-ua"
                  "children war-events"
                  "civilian healthcare"
                  "refugees institution"
                  ". ."
                  "title-ru title-ru"
                  "enemy enemy";
    grid-template-columns: 50%;
    grid-auto-columns: initial;
  }
}

@media screen and (max-width: 1000px) {
  .grid.grid-general .item-content-wrapper .flex.flex-row {
    flex-direction: column;
  }
}

.grid.grid-general > .grid-item.grid-item-children{ grid-area: children; }
@media screen and (min-width: 800px) {
  .grid.grid-general > .grid-item.grid-item-children{
    min-height: 540px;
  }
  .grid > .grid-item.grid-item-children .grid-link{
    padding-bottom: 6rem;
  }
}
.grid.grid-general > .grid-item.grid-item-war-events{ grid-area: war-events;}
.grid.grid-general > .grid-item.grid-item-civilian{ grid-area: civilian; }
.grid.grid-general > .grid-item.grid-item-refugees{ grid-area: refugees; }
.grid.grid-general > .grid-item.grid-item-institution{ grid-area: institution; }
.grid.grid-general > .grid-item.grid-item-healthcare{ grid-area: healthcare; }
.grid.grid-general > .grid-item.grid-item-enemy{ grid-area: enemy; }
.grid.grid-general > .grid-item.grid-item-title-ua{ grid-area: title-ua; padding-right: 1rem; }
.grid.grid-general > .grid-item.grid-item-title-ru{ grid-area: title-ru; }

@media screen and (min-width: 800px) {
  .grid.grid-general > .grid-item.grid-item-civilian h3 {
    margin-top: 0;
    flex: 1;
  }
}

.grid.grid-general > .grid-item.grid-item-war-events .Map { 
  height: calc(100% - 82px);
}
.grid.grid-general > .grid-item.grid-item-war-events .Map > div:not(.title){ 
  min-height: 100%;
}

.grid-item.grid-item-war-events  .mapboxgl-ctrl-bottom-right {
  bottom: 80px;
}

@media screen and (min-width: 800px) {
  .grid-item.grid-item-institution .data-wrap {
    display: flex;
  }
  .grid.grid-general > .grid-item.grid-item-institution .data-wrap h3{
    margin: 0;
    flex: 1;
  }
}

.grid-general .grid-item .icon [class^=path]{
  position: absolute;
  right: 0;
}

.grid-refugees {
  grid-template: "refugees refugees-back"
                 "refugees split-progress"
                 ". ."
                 ". ."
                 ". ."
                 ". ."
                 "refugees-stats refugees-stats";
}
.grid.grid-civilian {
  display: block;
  grid-template: none;
  grid-auto-columns: max-content;
    grid-template-columns: none;
    grid-gap: 0;
}

.grid.grid-refugees > .grid-item.grid-item-refugees{ grid-area: refugees; }
.grid.grid-refugees > .grid-item.grid-item-refugees-back{ grid-area: refugees-back; }
.grid.grid-refugees > .grid-item.grid-item-split-progress{ grid-area: split-progress; }
.grid.grid-refugees > .grid-item.grid-item-refugees-stats{ grid-area: refugees-stats; }

@media screen and (min-width: 800px) {
  .grid.grid-refugees > .grid-item.grid-item-refugees-back,
  .grid.grid-refugees > .grid-item.grid-item-split-progress{ 
    padding-left: 3rem;
  }
}

.grid.grid-refugees > .grid-item a {
  display: initial;
}

@media screen and (min-width: 800px) {
  .grid{
    grid-template-columns: 25%;
    grid-auto-columns: 1fr 1fr 1fr;
  }

  .grid.grid-refugees {
    grid-template-columns: 50%;
  }
}

@media screen and (max-width: 800px) {
  .grid{
      display: flex;
      flex-direction: column;
      /* text-align: left;
      grid-auto-flow: dense;
      grid-template: "mainData mainData"
                  ". ."
                  ". ."
                  ". ."
                  ". ."; */
  }
}

.grid > .grid-item {
  position: relative;
  padding: 0.5rem;
  overflow: hidden;
  border-radius: .5rem;
  transform: translateZ(0);
}

.grid > .grid-item:first-child ,
.grid > .grid-item:nth-of-type(8) {
  grid-area: mainData;
  display: flex;
  align-items: center;
}

.grid > .grid-item:nth-of-type(8) {
  grid-area: hlcpt;
}

.grid > .grid-item:nth-of-type(13) {
  grid-area: pli;
}

.grid > .grid-item:nth-of-type(14) {
  grid-area: li;
}

.grid > .grid-item:nth-of-type(15) {
  grid-area: msl;
}

@media screen and (min-width: 800px) {
  .grid > .grid-item:first-child h3 {
      margin-top: 0;
  }
}

.grid > .grid-item .value{
  font-size: 1.5rem;
}

@media screen and (min-width: 800px) {
  .grid > .grid-item:first-child .value{
      font-size: 5.5rem;
  }
}

.grid > .grid-item .grid-link {
  text-decoration: none;
  height: 100%;
  display: block;
  padding: 0.5rem;
  border-radius: 4px;
}

date {
  display: none;
}

.grid > .grid-item a date {
  display: block;
  position: absolute;
  right: 0;
  top: 3px;
  color: #9f9f9f;
  font-size: 1.2rem;
}

.grid > .grid-item a h3 {
  white-space: pre-wrap;
}

.grid > .grid-item a h3:first-of-type,
.grid > .grid-item a h3+h3 {
  margin-top: 0;
}


@media screen and (min-width: 800px) {
  .grid > .grid-item a h3:first-of-type {
    max-width: calc(100% - 30px);
  }  
}

@media screen and (max-width: 300px) {
  .grid > .grid-item a date {
    font-size: 0.8rem;
    top: -70px;
  }
}

@media screen and (min-width: 300px) and (max-width: 800px) {
  .grid > .grid-item a date {
    font-size: 0.8rem;
    top: auto;
    bottom: 0;
    left: 0;
    right: auto;
  }
}

.grid > .grid-item:focus a ,
.grid > .grid-item a:focus {
  box-shadow: 0 0 0 1px #ea3738;
}


@media screen and (min-width: 800px) {
  .grid > .grid-item a .item-content-wrapper {
    transition: 0.7s cubic-bezier(0, 0, 0.06, 1);
  }
  .grid > .grid-item a:active .item-content-wrapper {
    transform: scale(0.9);
  }
}


.credit {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 0.75rem;
  text-align: right;
}

.grid .number {
  font-size: 4.5rem;
  font-weight: 900;
  word-break: break-all;
  line-height: 5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  display: inline-block;
}

/* @media screen and (min-width: 800px) {
  .grid .number {
    font-size: 5.5rem;
    line-height: 5.5rem;
    margin-bottom: 1rem;
  } 
} */

.grid .number.text-l {
  line-height: 3rem; 
  font-size: 3rem; 
}

.grid .trend {
  font-size: 0.8rem;
}

.grid .trend {
  color: #cbcbcb;
}

.grid .trend.positive {
  color: #2ce63f;
}

.grid .trend.negative {
  color: #e62c3a;
}

.grid .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: grayscale(.7);
  opacity: 0.15;
  transition: 5s ease-out, opacity 0.5s ease-out;
  transform: scale(1.5) translate(-15%, 15%);
}

.grid .grid-item:hover .cover {
  opacity: 0.35;
  /* transition: 5s ease-out; */
  /* transform: scale(1) translate(0, 0); */
}

.grid .grid-item a .cover {
  transition: 0.7s cubic-bezier(0, 0, 0.06, 1), opacity 0.5s ease-out;
  transform: scale(1.2) translate(-5%, 5%);
}

@media screen and (min-width: 800px) {
  
  .grid .grid-item:hover a .cover ,
  .grid .grid-item:focus a .cover {
    transform: scale(1.22) translate(-6%, 6%);
  }
  .grid .grid-item:active a .cover {
    transform: scale(1.18) translate(-1%, 1%);
  }
}


.grid .item-content-wrapper {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template:
      "icon value"
      "icon type";
  text-align: left;
  grid-template-columns: max-content;
  padding-right: 50px;
}

.EnemyLoss .grid .item-content-wrapper h3{
  word-break: break-all;
}

.grid-general .item-content-wrapper {
  grid-template-columns: initial;
}

.grid .icon-wrapper {
  grid-area: icon;
  font-size: 3rem;
  max-width: 5rem;
  padding-right: 2rem;
  margin-top: 2rem;
}

.grid-general .grid-item .icon {
  font-size: 3rem; 
  width: 3rem; 
  height: 3rem; 
  color: #fff; 
  position: absolute; 
  bottom: 4rem; 
  right: 1rem;
}

@media screen and (min-width: 801px) {
  .grid.grid-general > .grid-item.grid-item-children .icon {
    right: auto;
    left: 1rem;
    /* font-size: 8rem;  */
  }
}
@media screen and (min-width: 800px) {
  .grid.grid-general > .grid-item.grid-item-institution .icon {
    margin-top: -2px;
  }
}

@media screen and (min-width: 600px) {
  .grid.grid-general > .grid-item:not(.grid-item-children) a.grid-link {
    padding-right: 5rem;
  }

  .grid.grid-general > .grid-item:not(.grid-item-children) a.grid-link date {
    right: -4.5rem;
  }

  .grid-general .grid-item .icon {
    font-size: 5rem;
    width: 5rem; 
    height: 5rem; 
    bottom: 3.5rem; 
  }
}
@media screen and (max-width: 800px) {

  .grid-general .grid-item .icon {
    bottom: auto;
    top: 1.5rem;
    right: 1rem;
  }
}

@media screen and (max-width: 300px) {
  .grid.grid-general > .grid-item{
    padding-right: 1rem;
    padding-top: 6rem;
  }

  .grid-general .grid-item .icon{
    right: auto;
    left: 1rem;
  }

  .grid .item-content-wrapper{
    grid-template: "icon"
                  "value"
                  "type";
  }
}


.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
  flex: 1;
}

@media screen and (min-width: 800px) {
  .grid > .grid-item:first-child .icon-wrapper {
      font-size: 4rem;
  }
}

.grid .value-wrapper {
  grid-area: value;
  margin: 1rem 0;
}

.grid .type {
  grid-area: type;
  margin: 0 0 1rem;
}

.grid-source {
  /* color: #FBF5F3; */
}

.grid-prev-date:empty {
  margin-left: 80px;
}
.grid-next-date:empty {
  margin-right: 80px;
}

.media-grid {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template: ". ."
                ". ."
                ". .";
  text-align: left;
  /* grid-template-columns: max-content; */
}

@media screen and (max-width: 800px) {
  .media-grid {

  grid-template: "."
    "."
    ".";
  } 
}

.media-grid-item {
  position: relative;
  display: flex;
  height: max(200px, 10vh);
  filter: grayscale(0.9);
}

.media-grid-item .media-grid-item-content {
  flex: 1;
  background-color: rgba(0,0,0,0.3);
}

.media-grid-item .text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 0.2s ease-out, transform 0.25s ease-out;
  padding: 1rem 3rem;
  backdrop-filter: blur(5px);
}

@media screen and (max-width: 800px) {
  .media-grid-item {
    height: max(200px, 30vh);
  }
}
@media screen and (min-width: 800px) {
  .media-grid-item .text {
    opacity: 0;
    transform: translate(0, 10%);
  }
}

.media-grid-item:hover ,
.media-grid-item:focus {
  filter: grayscale(0.1);
}

.media-grid-item:hover .media-grid-item-content,
.media-grid-item:focus .media-grid-item-content {
  background-color: transparent;
}

.media-grid-item:hover .text,
.media-grid-item:focus .text{
  opacity: 1;
  background-color: rgba(0,0,0,0.7);
  transform: translate(0, 0%);
}

.children-scale {
  display: grid;
  grid-template: '. . . . . . . . . . .';
}

.children-scale i {
  width: 3px;
  height: 3px;
  border-radius: 5px;
  margin: 0 auto 2vh;
}

.children-scale i:nth-of-type(odd) {
  background: #fff;
  box-shadow: 0 0 10px #fff;
}

.children-scale i.is-dead:nth-of-type(odd) {
  background: #9a1530;
  box-shadow: 0 0 9px 2px #9a1530;
}

.EnemyLossTotal {
  align-items: baseline;
}

@media screen and (max-width: 800px) {
  .EnemyLossTotal{
    flex-direction: column;
  }

  .grid .number {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
}

.mapboxgl-popup {
  z-index: 999;
}

.carrete {
  position: absolute;
  height: 50px;
  width: 16px;
  top: 0px;
  left: calc((100% - 16px) * 1);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 7px;
}
.carrete .value {
  background-color: rgba(77, 77, 77, 0.4);
  backdrop-filter: blur(5px);
  padding: 1rem;
  position: absolute;
  color: #fff;
  
  top: -10px;
  transform: translateX(-50%) translateY(-100%);
  padding: 0.4rem 0.8rem;
  border-radius: 7px;
  white-space: nowrap;
  left: 8px;
  font-size: 0.8rem;
}

.grid > .grid-item .carrete > .value {
  font-size: 0.8rem;
  z-index: 999;
}


.carrete .value:after {
  content: '';
  position: absolute;
  width: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  border: solid transparent 5px;
  border-top-color: rgba(77, 77, 77, 0.4);
}

.overlays .mapboxgl-marker .icon {
  font-size: 24px;
  width: auto;
  height: auto;
  position: static;
}

.Map.as-widget .title {
  background: #1b1923;
  text-align: left;
  padding: 1rem;
  transition: opacity 0.7s cubic-bezier(0, 0, 0.06, 1);
}

.Map.as-widget .title:hover {
  background: #252230;
}

.Map.as-widget .title:active {
  background: #15131b;
}


.Map.as-widget .mapbar{
  padding: 1rem 2rem;
  height: 80px;
  background: rgb(0 0 0 / 70%);
  margin-top: -80px;
  position: relative;
  z-index: 999;
  backdrop-filter: blur(7px);
}

.split-progress .split-item:not(:hover):not(:focus) {
  opacity: 0.9;
}
